define("additive-content/controllers/instance/multimedia/index", ["exports", "additive-content/config/environment", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency", "@additive-apps/media/constants"], function (_exports, _environment, _controller, _object, _service, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceMultimediaIndexController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* (folder) {
    if (!folder) {
      return this.uiDialog.showError();
    }
    try {
      const response = yield this.authenticatedFetch.fetch(`${_environment.default.APP.mediaApi}/${this.currentUser.currentOrganization.id}/folders/${folder.id}/download`, {
        headers: {
          Accept: 'application/vnd.additive+json; version=1'
        },
        method: 'POST'
      }, null, [404]);
      if (!response || !response.ok) {
        throw response;
      }
      this.uiToast.showToast({
        title: this.intl.t('multimedia.bulkDownload.success.title'),
        description: this.intl.t('multimedia.bulkDownload.success.description'),
        type: 'success'
      });
    } catch (e) {
      if (e.status === 404) {
        this.uiDialog.showError(this.intl.t('multimedia.bulkDownload.error.title'), this.intl.t('multimedia.bulkDownload.error.description'));
        return;
      }
      this.uiDialog.showError();
    }
  }), (_class = class InstanceMultimediaIndexController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "multimedia", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      /**
       * The currentFolder model
       * @property currentFolder
       * @type {Medium}
       */
      _defineProperty(this, "currentFolder", null);
      /**
       * Downloads the content of the folder
       *
       * @property downloadFolder
       * @param {Object} folder, the folder to be downloaded
       * @type {Task}
       */
      _initializerDefineProperty(this, "downloadFolder", _descriptor8, this);
    }
    get _multimediaBaseRouteName() {
      const currentRoute = this.router.currentRouteName;
      const index = currentRoute.indexOf(_constants.MEDIA_ENGINE_NAME);

      // extract route path including engine name
      return currentRoute.substr(0, index + _constants.MEDIA_ENGINE_NAME.length);
    }
    get isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
    openFilter() {
      this.multimedia.folderBeforeFilter = this.multimedia.currentFolder;
      this.router.transitionTo(`${this._multimediaBaseRouteName}.filter`);
    }
    onDownloadFolder(folder) {
      const intl = this.intl;
      this.uiDialog.showConfirm(intl.t('multimedia.bulkDownload.confirm.title'), intl.t('multimedia.bulkDownload.confirm.description'), () => this.downloadFolder.perform(folder), intl.t('multimedia.bulkDownload.download'), true);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "multimedia", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openFilter"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "downloadFolder", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onDownloadFolder", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDownloadFolder"), _class.prototype)), _class));
});